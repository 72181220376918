/* ContentSection.css */

.swiper {
  height: 550px;
  width: 100%;
}

.swiper img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";
  color: var(--primary-color);
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
  color: var(--primary-color);
}

.swiper-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  max-width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
}

/* Content Container Styles */
.content-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
}

/* Heading Styles */
.heading-section h1 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 20px;
}

.cta-button {
  margin-top: 40px;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  padding: 10px 33px;
  border: 2px solid #f6efe7;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.cta-button:hover {
  background-color: transparent;
  border-color: #a6a19b;
}
