.custom-swiper{
    max-width: 80%;
    height: 315px;
    margin-bottom: 50px;
}

.serv-inf2{
    background-color: white;
    text-align: center;
}

.head{
    text-align: center;
    margin-bottom: 30px;
}
.h{
    font-size: 24px;
    margin: 0 0 34px;
}

.heading{
    font-size: 46px;
    margin-bottom: 10px;
}

.min{
    margin-bottom: 240px;
}

.al-hadith-container.c{
    margin-bottom: 250px;
}
